import { IMaxLoanTable, ILtvCalcInput, ILtvCalcOutput } from './types';

export const MinHousePrice = 70000;

let minLoan: IMaxLoanTable = {};
let tblHealthy: IMaxLoanTable = {};
let tblUnhealthy: IMaxLoanTable = {};
let tblUnhealthyJoint: IMaxLoanTable = {};
let tblDate = "";
let tblMaxHousePrice = 1000000;

async function loadData() {
  let currentType = 'ltv-later-living-now';

  let minData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/min');
  let healthyData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/healthy');
  let unhealthyData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/unhealthy');
  let unhealthyJointData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/unhealthyJoint');
  let serverDate = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/date');
  let maxHousePrice = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/max');

  minLoan = await(minData).json();
  tblHealthy = await(healthyData).json();
  tblUnhealthy = await(unhealthyData).json();
  tblUnhealthyJoint = await(unhealthyJointData).json();
  tblDate = await(serverDate).text();
  tblMaxHousePrice = parseInt(await(maxHousePrice).text());
}

loadData();

export const getLtvValue = (input: ILtvCalcInput): ILtvCalcOutput => {
  const { clientAge, housePrice, isJoint, date, maxHousePrice, ltvPlan } = input;

  const age = (clientAge > 100) ? 100 : clientAge;

  let valMin: number = minLoan[age];
  let valHealthy: number = tblHealthy[age];

  const MinLoan = Math.round( housePrice * 0.01 * valMin);
  const Healthy = Math.round( housePrice * 0.01 * valHealthy);
  let Unhealthy = Math.round( housePrice * 0.01 * tblUnhealthy[age]);

  if(isJoint)
  {
    Unhealthy = Math.round( housePrice * 0.01 * tblUnhealthyJoint[age]);
  }

  const plans = { MinLoan, Healthy, Unhealthy };
  const result: ILtvCalcOutput = { plans, max: valHealthy, diff: 0, pct: {}, date: tblDate, maxHousePrice: tblMaxHousePrice };
  if (result.max > result.plans.Unhealthy) {
    result.max = result.plans.Unhealthy
  }
  result.diff = result.max - result.plans.MinLoan;
  result.pct = { 'MinLoan': minLoan[age], 'Healthy': tblHealthy[age], 'Unhealthy': tblUnhealthy[age] };

  if(isJoint)
  {
    result.pct = { 'MinLoan': minLoan[age], 'Healthy': tblHealthy[age], 'Unhealthy': tblUnhealthyJoint[age] };
  }

  return result;
};
